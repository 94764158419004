.terms-and-conditions {
	padding: 20px;
	.title {
		text-align: center;
		margin-block-end: 48px;
		font-size: 24px;
		font-weight: bold;
	}
	ol > li {
		&::marker {
			font-weight: bold;
		}

		margin: 18px 0 24px;

		> strong:not(:first-child) {
			display: block;
			margin-block: 12px 4px;
		}

		p {
			margin-block: 8px;
		}
	}
}
