@import "assets/stylesheets/css/main.css";
@import "assets/stylesheets/vendors/fontawesome6/css/all.css";

.hotbox-wrapper {
	margin: -10px;
	display: flex;
}

.hotbox-item {
	padding: 10px;
	width: 50%;
}

.hotbox {
	background: var(--primary);
	display: flex;
	padding: 30px 20px;
	color: white;
	height: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	transition: all 300ms ease-in-out;
}

.hotbox i {
	font-size: 50px;
	line-height: 55px;
	margin-bottom: 20px;
	font-weight: 100;
	text-align: center;
	transition: all 300ms ease-in-out;
}

.hotbox span {
	display: block;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
}

.hotbox:hover {
	text-decoration: none !important;
	/* background-color: var(--info); */
	background-color: #dc8f17;
}

.hotbox:hover span,
.hotbox:hover i {
	color: white;
}

.hotbox:hover i {
	transform: scale(1.1);
}

.k-form .k-multiselect {
	height: auto;
	min-height: 50px;
}

.App-footer .k-panelbar-group > li > div > .k-link:hover {
	background-color: #f6e9e6 !important;
	color: #212529 !important;
}

.App-footer .k-panelbar-group > li > .k-link.k-selected .k-panelbar-item-text {
	color: white;
}
.App-footer .k-panelbar-group > li > .k-link.k-selected:hover .k-panelbar-item-text {
	/* color: black; */
}

@media only screen and (max-width: 480px) {
	.hotbox {
		padding: 15px 10px;
	}

	.hotbox-wrapper {
		margin: -5px;
	}

	.hotbox-item {
		width: 100%;
		padding: 5px;
	}

	.hotbox i {
		font-size: 35px;
		margin-bottom: 0;
		width: 50px;
	}

	.hotbox span {
		font-size: 12px;
		line-height: 16px;
		text-align: center;
	}
}

.k-calendar .k-calendar-td:hover .k-link {
	color: white !important;
}

.multiselect-header {
	display: block;
	padding: 4px 8px;
	cursor: pointer;
}

.multiselect-header.empty {
	background: var(--primary);
	color: white;
}
