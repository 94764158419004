.k-checkbox {
  border-color: rgba(165, 42, 42, 0.6);
}

.bold-label {
  font-weight: bold;
}

.bold-label .k-checkbox-label {
  margin-left: 12px;
}

.k-button-icon.k-icon.k-i-calendar {
  color: rgba(165, 42, 42);
}
