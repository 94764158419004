.App-footer {
  .k-link.k-selected:hover {
    color: var(--primary) !important;
    background-color: #f6e9e5 !important;
  }
  .k-link:hover {
    .k-panelbar-item-text {
      color: var(--primary) !important;
    }
    background-color: #f6e9e5 !important;
  }
}
