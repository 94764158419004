/* PWAInstaller.css */

.pwa-installer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: 99999999;
  }
  
  .pwa-installer-container.show {
    transform: translateY(0);
  }
  
  .pwa-installer-content {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .pwa-installer-content div{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pwa-installer-content div .app-icon{
    width: 40px;
    height: 40px;
  }
  .pwa-installer-content div .app-name{
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
    margin-left: 10px;
    width: calc(100% - 50px);
    margin-top: 10px;
  }

  .install-button {
    margin-left: auto;
  }

  /* Dark mode styles using prefers-color-scheme media query */
@media (prefers-color-scheme: dark) {
    .pwa-installer-container {
      background-color: #333;
      box-shadow: 0 -2px 4px rgba(255, 255, 255, 0.2);
    }
  
    .pwa-installer-content div .app-name {
      color: #fff;
    }
  
    .pwa-installer-content div .app-url {
      color: #ccc;
    }
  
    .pwa-installer-container.show {
      /* Add styles for dark mode when the prompt is shown */
    }
  
    .install-button {
    }
  }